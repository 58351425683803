import React from 'react';
import { ConnectAccountOnboarding } from '@stripe/react-connect-js';

const AccountOnboardingUI = () => {
  return (
    <ConnectAccountOnboarding
      onExit={() => {
        console.log('The account has exited onboarding');
      }}
      // Optional: make sure to follow our policy instructions above
      // fullTermsOfServiceUrl="{{URL}}"
      // recipientTermsOfServiceUrl="{{URL}}"
      // privacyPolicyUrl="{{URL}}"
      // skipTermsOfServiceCollection={false}
      // collectionOptions={{
      //   fields: 'eventually_due',
      //   futureRequirements: 'include',
      // }}
    />
  );
};

export default AccountOnboardingUI;
