// src/App.jsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import { ConnectComponentsProvider } from '@stripe/react-connect-js';
import AccountOnboardingUI from './AccountOnboardingUI';
import { config } from './config'; // Import the config

console.log('App.jsx loaded');
console.log(`Using Stripe PK: ${config.stripePK}`);
console.log(`Using API v2 URL: ${config.apiV2Url}`);

export default function App() {
  const { accountId } = useParams();
  const [stripeConnectInstance, setStripeConnectInstance] = useState(null);

  useEffect(() => {
    const sign = async () => {
      try {
        const response = await fetch(`${config.apiV2Url}/Auth/generateToken`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            secretKey: '5521BD4E57A741F137616C0DD13F7112DCD3AC57F8D00ADC932B8BD051233B71',
            uid: '1000',
          }),
        });

        const data = await response.json();
        if (data.success) {
          return data.idToken;
        } else {
          console.error('Failed to generate token', data);
          throw new Error('Failed to generate token');
        }
      } catch (error) {
        console.error('Error in sign function:', error);
        throw error;
      }
    };

    const fetchClientSecret = async () => {
      try {
        const token = await sign();
        const response = await fetch(`${config.apiV2Url}/stripe/getAccountSession`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
          body: JSON.stringify({ accountId }),
        });

        const data = await response.json();
        if (data.sucess) {
          return data.client_secret;
        } else {
          console.error('Failed to fetch client secret', data);
          throw new Error('Failed to fetch client secret');
        }
      } catch (error) {
        console.error('Error in fetchClientSecret function:', error);
        throw error;
      }
    };

    const initializeStripeConnect = async () => {
      try {
        const instance = await loadConnectAndInitialize({
          publishableKey: config.stripePK, // Use the stripePK from config
          fetchClientSecret,
        });

        setStripeConnectInstance(instance);
      } catch (error) {
        console.error('Failed to initialize Stripe Connect:', error);
      }
    };

    if (accountId) {
      initializeStripeConnect();
    }
  }, [accountId]);

  if (!stripeConnectInstance) {
    return <div>Loading...</div>;
  }

  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <AccountOnboardingUI />
    </ConnectComponentsProvider>
  );
}