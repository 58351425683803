// src/config.js

const getConfig = () => {
    const env = process.env.REACT_APP_ENV;
  
    switch (env) {
      case 'prod':
        return {
          stripePK: process.env.REACT_APP_STRIPE_PK_PROD,
          apiV2Url: process.env.REACT_APP_APIV2_PROD,
        };
      case 'homolog':
        return {
          stripePK: process.env.REACT_APP_STRIPE_PK_HOMOLOG,
          apiV2Url: process.env.REACT_APP_APIV2_HOMOLOG,
        };
      case 'development':
      default:
        return {
          stripePK: process.env.REACT_APP_STRIPE_PK_DEV,
          apiV2Url: process.env.REACT_APP_APIV2_DEV,
        };
    }
  };
  
export const config = getConfig();  